<!--
 * @Description: 巡查管理--巡查通用内容--表单页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: 小广
 * @LastEditTime: 2019-07-04 15:16:29
 -->
<template>
  <div class="inspectManagerContentForm-wrapper">
    <form-panel
      ref="formPanel"
      :form="form"
      submitText="保存"
      v-bind="submitConfig"
      :submitUrl="submitUrl"
      :footerShow="true"
      :submitSuccess="submitSuccess"
      :submitBefore="submitBefore"
      :submitError="submitError"
    >
      <col2-detail>
        <col2-block title=" 巡查内容信息">
          <!-- 新增 -->
          <template v-if="!isCommunityUser">
            <el-form-item prop="regionId" v-if="isAdd" label="所属公司" :rules="[{ required: true, message: '请选择所属公司', trigger: 'change' }]">
              <v-select2 :width="193" v-model="form.regionId" placeholder="所属公司" v-bind="regionIdParam" @onChange="onRegionIdChange" />
            </el-form-item>
            <col2-item v-else label="所属公司" :text="regionName"></col2-item>
          </template>
          <el-form-item
            v-if="isAdd"
            label="巡查类型"
            prop="type"
            :rules="[ { required: true, message: '当前选项不允许为空', trigger: 'change' } ]"
          >
            <v-select
              v-model="form.type"
              :options="typeOps"
              :width="193"
            ></v-select>
          </el-form-item>
          <col2-item
            v-else
            :span="24"
            label="巡查类型"
            :text="typeMap[detailItem.type]"
          />
          <el-form-item
            v-if="isAdd"
            label="巡查分类"
            prop="selectIds"
            :rules="{ type: 'array',required: true, message: '当前选项不允许为空', trigger: 'change' }"
          >
            <v-cascader
              :value.sync="form.selectIds"
              :options="categoryList"
              :props="props"
            ></v-cascader>
          </el-form-item>
          <col2-item v-else :span="24" label="巡查分类">
            <span class="second-category">{{ detailItem.parentName }}</span>
            <span>{{ detailItem.name }}</span>
          </col2-item>
          <el-form-item label="巡查要点" required>
            <el-form-item
              class="content-wrapper"
              v-for="(item, index) in form.contents"
              :key="index"
              :prop="'contents.' + index + '.content'"
              :rules="{
                required: true,
                message: '要点内容不允许为空',
                trigger: 'change'
              }"
            >
              <v-input
                class="first-margin"
                v-model="item.content"
                placeholder="请输入要点内容"
                :width="193"
                :maxlength="300"
              ></v-input>
              <v-button
                v-if="form.contents.length !== 1"
                class="second-margin"
                type="danger"
                text="删除"
                @click="plusMinusAction(false, index)"
              ></v-button>
              <v-button
                v-if="index === 0"
                text="添加巡查要点"
                @click="plusMinusAction(true, 0)"
              ></v-button>
            </el-form-item>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import {
  getInspectCategoryAllURL,
  getInspectCategoryContentURL,
  submitInspectContentURL
} from './api'
import { typeOps, typeMap } from './map'
import { Col2Block, Col2Item, Col2Detail } from 'components/bussiness'
import { vCascader } from 'components/control'
import { regionParams } from 'common/select2Params'

export default {
  name: '',
  components: {
    Col2Block,
    Col2Item,
    Col2Detail,
    vCascader
  },
  data () {
    return {
      submitUrl: submitInspectContentURL,
      submitConfig: {},
      typeOps: this.getTypeOps(),
      typeMap: typeMap,
      firstOps: [],
      secondOps: [],
      detailItem: {},
      regionIdParam: regionParams,
      regionName: '',
      form: {
        categoryId: undefined,
        content: [''],
        // 默认取typeOps里第一个
        type: this.getDefaultType(),
        contents: [{ content: '' }],
        selectIds: []
      },
      props: {
        value: 'categoryId',
        label: 'name',
        children: 'children'
      },
      isAdd: false,
      // 分类的list
      allCategoryList: [],
      categoryList: []
    }
  },

  computed: {
    isCommunityUser () {
      const userType = String(this.$store.state.userInfo.userType)
      return userType === '101' || userType === '102'
    }
  },

  created () {
    // 默认新增
    let add = true
    if (this.$route.query && this.$route.query.id) {
      // 编辑put
      add = false
    }
    this.isAdd = add
    this.submitConfig = {
      submitMethod: add ? 'post' : 'put'
    }

    let title = add ? '新增通用内容' : '修改通用内容'
    // 设置title
    this.$setBreadcrumb(title)
  },

  // 加载详情数据
  mounted () {
    if (this.$route.query && this.$route.query.id) {
      // 获取详情
      this.getDetailData()
    } else {
      // 获取分类
      this.requestForCategory()
    }
  },

  watch: {
    'form.type' (newValue) {
      if (this.isAdd) {
        /// 巡查类型变化，
        this.requestForCategory()
      }
    },

    'form.selectIds' (newValue) {
      if (this.isAdd) {
        // 代表重新选择了
        let categoryId = ''
        if (newValue && newValue.length > 1) {
          categoryId = newValue[1]
        }
        this.$set(this.form, 'categoryId', categoryId)
      }
    },

    'form.categoryId' (newValue) {
      if (this.isAdd && newValue) {
        /// 二级分类改变 巡查要点数据重新获取
        this.requestForGetCategoryContent()
      }
    }
  },

  methods: {
    // 获取巡查类型
    getTypeOps () {
      return typeOps()
    },

    // 获取默认type
    getDefaultType () {
      let typeItem = this.getTypeOps()[0]
      return typeItem.value
    },

    plusMinusAction (add, index) {
      if (add) {
        // 添加
        let num = 100;
        if (this.form.contents.length < num) {
          this.form.contents.push({
            content: ''
          })
        } else {
          this.$message({
            type: 'error',
            message: `最多只能添加${ num }个巡查要点`,
            center: true
          })
        }
      } else {
        // 减少
        this.form.contents.splice(index, 1)
      }
    },

    // 获取展示的分类
    getShowLevelList () {
      if (this.allCategoryList.length) {
        let firstLevelList = []
        let selectIds = []
        this.allCategoryList.forEach((category, index) => {
          if (category.level === 1) {
            // 一类
            let tempCategory = Object.assign({}, category)
            // 根据一级的categoryId取到对应的二级数组
            let secondLevelList = this.getSecondLevelList(category.categoryId)
            if (secondLevelList && secondLevelList.length) {
              tempCategory['children'] = secondLevelList
              if (firstLevelList.length === 0) {
                // 设置默认选中
                let firstId = category.categoryId
                let secondCategory = secondLevelList[0]
                let secondId = secondCategory.categoryId
                selectIds = [firstId, secondId]
                this.$set(this.form, 'selectIds', selectIds)
              }
            }
            firstLevelList.push(tempCategory)
          }
        })
        this.categoryList = firstLevelList
      } else {
        this.categoryList = []
        this.$set(this.form, 'selectIds', [])
        this.form.contents = [{ content: '' }]
      }
    },

    async onRegionIdChange () {
      this.requestForCategory()
    },

    // 获取二级分类数组
    getSecondLevelList (parentId) {
      let secondLevelList = []
      if (this.allCategoryList.length && parentId) {
        this.allCategoryList.forEach((category, index) => {
          if (category.level === 2 && parentId === category.parentId) {
            secondLevelList.push(category)
          }
        })
      }
      return secondLevelList
    },

    // 获取详情数据
    getDetailData () {
      let _this = this
      let requestUrl = `${getInspectCategoryAllURL}/${this.$route.query.id}`
      this.$axios.get(requestUrl).then(res => {
        if (res.status === 100) {
          _this.detailItem = res.data || {}
          _this.regionName = res.data.regionName
          _this.updatForm()
        }
      })
    },

    // 更新form数据
    updatForm () {
      let form = {
        id: this.detailItem.id,
        type: this.detailItem.type,
        categoryId: this.detailItem.categoryId,
        content: this.detailItem.content
      }

      let tempList = this.detailItem.content || []
      let contentList = []
      tempList.forEach(item => {
        contentList.push({
          content: item
        })
      })
      if (contentList.length === 0) {
        contentList.push({ content: '' })
      }
      form.contents = contentList
      this.form = Object.assign({}, form)
    },

    // 根据类型获取所有分类
    requestForCategory () {
      let _this = this
      let getParams = {
        params: {
          type: this.form.type,
          regionId: this.form.regionId
        }
      }
      this.$axios.get(getInspectCategoryAllURL, getParams).then(res => {
        if (res.status === 100) {
          // 真对一级二级分类进行处理
          let tempList = res.data || []
          _this.allCategoryList = tempList
          _this.getShowLevelList()
        }
      })
    },

    // 获取巡查要点
    requestForGetCategoryContent () {
      let _this = this
      let requestUrl = `${getInspectCategoryContentURL}/${
        this.form.categoryId
      }`
      this.$axios.get(requestUrl).then(res => {
        if (res.status === 100) {
          // 处理巡查要点
          let tempList = res.data || []
          let contentList = []
          tempList.forEach((item, index) => {
            contentList.push({
              content: item
            })
          })
          if (contentList.length === 0) {
            contentList.push({ content: '' })
          }
          _this.$set(_this.form, 'contents', contentList)
        }
      })
    },

    submitBefore (data) {
      let tempList = []
      let contents = data.contents
      contents.forEach(item => {
        tempList.push(item.content)
      })
      data.content = JSON.stringify(tempList)
      // 删除多余字段
      delete data.contents
      delete data.firstCategoryId
      delete data.selectIds
      return true
    },

    /// 提交成功的处理
    submitSuccess (data) {
      // 跳转到列表
      this.$router.go(-1)
    },

    /// 提交失败的处理
    submitError (data) {
      console.log('submitError', data)
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.inspectManagerContentForm-wrapper {

  .second-category {
      margin-right: 30px;
    }

  .content-wrapper {
    display: flex;
    margin-bottom: 20px;

    .first-margin {
      margin-right: 30px;
    }

    .second-margin {
      margin-right: 20px;
    }
  }
}
</style>
