var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inspectManagerContentForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitText: "保存",
              submitUrl: _vm.submitUrl,
              footerShow: true,
              submitSuccess: _vm.submitSuccess,
              submitBefore: _vm.submitBefore,
              submitError: _vm.submitError,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: " 巡查内容信息" } },
                [
                  !_vm.isCommunityUser
                    ? [
                        _vm.isAdd
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "regionId",
                                  label: "所属公司",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择所属公司",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "v-select2",
                                  _vm._b(
                                    {
                                      attrs: {
                                        width: 193,
                                        placeholder: "所属公司",
                                      },
                                      on: { onChange: _vm.onRegionIdChange },
                                      model: {
                                        value: _vm.form.regionId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "regionId", $$v)
                                        },
                                        expression: "form.regionId",
                                      },
                                    },
                                    "v-select2",
                                    _vm.regionIdParam,
                                    false
                                  )
                                ),
                              ],
                              1
                            )
                          : _c("col2-item", {
                              attrs: {
                                label: "所属公司",
                                text: _vm.regionName,
                              },
                            }),
                      ]
                    : _vm._e(),
                  _vm.isAdd
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "巡查类型",
                            prop: "type",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.typeOps, width: 193 },
                            model: {
                              value: _vm.form.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "type", $$v)
                              },
                              expression: "form.type",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "巡查类型",
                          text: _vm.typeMap[_vm.detailItem.type],
                        },
                      }),
                  _vm.isAdd
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "巡查分类",
                            prop: "selectIds",
                            rules: {
                              type: "array",
                              required: true,
                              message: "当前选项不允许为空",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c("v-cascader", {
                            attrs: {
                              value: _vm.form.selectIds,
                              options: _vm.categoryList,
                              props: _vm.props,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.form, "selectIds", $event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "col2-item",
                        { attrs: { span: 24, label: "巡查分类" } },
                        [
                          _c("span", { staticClass: "second-category" }, [
                            _vm._v(_vm._s(_vm.detailItem.parentName)),
                          ]),
                          _c("span", [_vm._v(_vm._s(_vm.detailItem.name))]),
                        ]
                      ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "巡查要点", required: "" } },
                    _vm._l(_vm.form.contents, function (item, index) {
                      return _c(
                        "el-form-item",
                        {
                          key: index,
                          staticClass: "content-wrapper",
                          attrs: {
                            prop: "contents." + index + ".content",
                            rules: {
                              required: true,
                              message: "要点内容不允许为空",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c("v-input", {
                            staticClass: "first-margin",
                            attrs: {
                              placeholder: "请输入要点内容",
                              width: 193,
                              maxlength: 300,
                            },
                            model: {
                              value: item.content,
                              callback: function ($$v) {
                                _vm.$set(item, "content", $$v)
                              },
                              expression: "item.content",
                            },
                          }),
                          _vm.form.contents.length !== 1
                            ? _c("v-button", {
                                staticClass: "second-margin",
                                attrs: { type: "danger", text: "删除" },
                                on: {
                                  click: function ($event) {
                                    return _vm.plusMinusAction(false, index)
                                  },
                                },
                              })
                            : _vm._e(),
                          index === 0
                            ? _c("v-button", {
                                attrs: { text: "添加巡查要点" },
                                on: {
                                  click: function ($event) {
                                    return _vm.plusMinusAction(true, 0)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }